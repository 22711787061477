import { setMessageOpen } from "./organisation"
import { API, GET_APP_SETTINGS, SET_APP_SETTINGS } from "./types"

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onSuccessMessage = () => {},
  onFailure = () => {},
  onUploadProgress = () => {},
  label = "",
  headersOverride = null,
  message = "",
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onSuccessMessage,
      onFailure,
      onUploadProgress,
      label,
      headersOverride,
      message,
    },
  }
}

export function setAppSettings(data) {
  // const data2 = {
  //   id: 1,
  //   tenant_id: null,
  //   data: {
  //     features: {
  //       coreIDV: false,
  //       corePms: true,
  //       pmsAdmin: true,
  //       coreAdmin: true,
  //       coreAdminUsers: true,
  //       coreSalesForce: true,
  //       pmsClientPortal: true,
  //     },
  //   },
  //   status: 1,
  //   created_at: null,
  //   updated_at: null,
  // }
  return {
    type: SET_APP_SETTINGS,
    payload: data,
  }
}

export function getAppSettings() {
  return apiAction({
    method: "GET",
    url: `${process.env.REACT_APP_DOCUMENT_API_BASE_URI}app/settings`,
    onSuccess: setAppSettings,
    onFailure: () => {
      return setMessageOpen({
        open: true,
        message: "Error loading app settings",
        error: true,
      })
    },
    label: GET_APP_SETTINGS,
  })
}
