// import "react-app-polyfill/ie11"
// import "react-app-polyfill/stable"

import React, { useEffect, useState } from "react"
// import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import Axios from "axios"
// import Typography from "@material-ui/core/Typography"
import Helmet from "react-helmet"
import DateFnsUtils from "@date-io/date-fns"
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "styled-components"
import { useGA4React } from "ga-4-react"
import { create } from "jss"
import App from "./App"
import Error from "./components/Error/Error"
import Loader from "./components/Loader"

import maTheme from "./theme"

import store from "./redux/store/index"
import { Auth0Provider } from "./common/react-auth0-spa"
import history from "./common/history"
import { getAppSettings as propGetAppSettings } from "./actions/appConfig"

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
})

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const renderLoading = () => {
  return <Loader />
}

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split("&")
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

const Main = () => {
  const {
    themeReducer: theme,
    appSettingsReducers: appSettings,
  } = store.getState()
  const loadingComponent = renderLoading()
  const [load, setLoad] = useState(true)
  const [loadError, setLoadError] = useState(null)
  const [myConnection, setConnection] = useState("")

  // eslint-disable-next-line no-unused-vars
  const ga = useGA4React()
  // console.log("useGA4React", ga)

  const renderError = () => {
    // return (
    //   <Typography variant="h4">
    //     {loadError?.message || "Unknown Error While loading"}
    //   </Typography>
    // )

    return (
      <>
        <Helmet
          titleTemplate="%s | Saffron IOS"
          defaultTitle="Saffron IOS - Dashboard"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
              <ThemeProvider theme={maTheme[theme.currentTheme]}>
                <Error />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </>
    )
  }

  const loadAuthTenant = async () => {
    try {
      setLoad(true)
      const domain = window.location.hostname
      if (process.env.NODE_ENV === "development") {
        // domain = process.env.REACT_APP_BASE_DOMAIN
      }
      console.log("domain-env", process.env.NODE_ENV)
      console.log("domain", domain)
      const url = `${process.env.REACT_APP_API_BASE_URI}tenant/domain/${domain}`
      const response = await Axios.get(url, {
        method: "GET",
        params: {
          request_user_id: localStorage.getItem("saffron-user-id"),
        },
      })
      // console.log("response", response)
      if (response.status === 200 && response.data) {
        setConnection(response.data)
        // console.log("setConnection", response.data)
        localStorage.setItem("tenantData", JSON.stringify(response.data))
      } else {
        setLoadError({ message: "Error Loading Tenant" })
        localStorage.setItem("tenantData", "")
      }
      setLoad(false)
    } catch (error) {
      // console.log(error)
      setLoad(false)
      localStorage.setItem("tenantData", "")
      setLoadError(error)
    }
  }

  const loadQueryParam = () => {
    const error = getQueryVariable("error")
    if (error !== false) {
      setLoadError({ message: error })
    }
  }

  const getAppSettings = () => {
    store.dispatch(propGetAppSettings())
  }

  useEffect(() => {
    // eslint-disable-next-line
    loadAuthTenant()
    loadQueryParam()
    getAppSettings()
  }, [])
  // if (!load && !loadError) {
  //   console.log("myConnection", myConnection)
  // }

  const loading = load || appSettings.isLoading
  return (
    <Provider store={store}>
      {/* <PersistGate loading={loadingComponent} persistor={persistor}> */}
      {loading && loadingComponent}
      {!loading && loadError && renderError()}
      {!loading && !loadError && (
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          redirect_uri={window.location.origin}
          connection={
            myConnection.connection && myConnection.connection !== ""
              ? myConnection.connection
              : "none"
          }
          // connection="tenant1"
          useRefreshTokens
          onRedirectCallback={onRedirectCallback}
        >
          <App />
        </Auth0Provider>
      )}
      {/* </PersistGate> */}
    </Provider>
  )
}

export default Main
