import {
  API_START,
  SET_APP_SETTINGS,
  GET_APP_SETTINGS,
  API_END,
} from "../../actions/types"

// Features Configuration
const isPMS = process.env.REACT_APP_PMS_ACTIVE === "true"
const isSalesforce = process.env.REACT_APP_SALES_FORCES === "true"
const isIDV = process.env.REACT_APP_IDV_ACTIVE === "true"
const isAdmin = process.env.REACT_APP_ADMIN_ACTIVE === "true"
const isUserAdmin = process.env.REACT_APP_USER_ADMIN_ACTIVE === "true"
const isAMLKYC = process.env.REACT_APP_AML_KYC_ACTIVE === "true"

const defaultAppConfig = {
  id: 1,
  tenant_id: null,
  data: {
    features: {
      coreIDV: isIDV,
      corePms: isPMS,
      pmsAdmin: true,
      coreAdmin: isAdmin,
      coreAdminUsers: isUserAdmin,
      coreSalesForce: isSalesforce,
      pmsClientPortal: true,
      coreAmlKyc: isAMLKYC,
    },
  },
  status: 1,
  created_at: null,
  updated_at: null,
}

const initialState = {
  isLoading: true,
  appSettings: defaultAppConfig,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_SETTINGS:
      console.log("SET_APP_SETTINGS", action.payload)
      return {
        ...state,
        appSettings: action.payload,
      }

    case API_START:
      if (action.payload === GET_APP_SETTINGS) {
        return {
          ...state,
          isLoading: true,
        }
      }
      return state

    case API_END:
      if (action.payload === GET_APP_SETTINGS) {
        return {
          ...state,
          isLoading: false,
        }
      }
      return state

    default:
      return state
  }
}
