import React from "react"
import styled from "styled-components"
import { rgba, darken } from "polished"
import PropTypes from "prop-types"
import { Chip, ListItem, ListItemText } from "@material-ui/core"
import {
  NavLink as RouterNavLink,
  withRouter,
  // Link as MainLink,
} from "react-router-dom"
import { connect } from "react-redux"
import { setOrgEntityName } from "../actions/organisation"
import { setPersonEntityName } from "../actions/person"

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  color: #A5A5A5;
  &:hover {
   // background: rgba(0, 0, 0, 0.08);
  }

  span {
    font-size:12px !important;
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  
  &:hover span {
    color: #FF9600; //${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    //background-color: ${(props) =>
      darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

// const LLink = styled(MainLink)`
//   padding-left: ${(props) => props.theme.spacing(15)}px;
//   padding-top: ${(props) => props.theme.spacing(2)}px;
//   padding-bottom: ${(props) => props.theme.spacing(2)}px;

//   &:hover {
//     color: #FF9600;
//   }

//   color: #A5A5A5;

//   text-decoration: none;
//   span {
//     //color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};

//     color: #A5A5A5;
//   }

//   &:hover span {
//     color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
//   }

//   &.${(props) => props.activeClassName} {
//    // background-color: ${(props) =>
//      darken(0.06, props.theme.sidebar.background)};

//     span {
//       color: ${(props) => props.theme.sidebar.color};
//     }
//   }
// `

// TODO - Add marging left to theme
const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
`

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  //background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`

function SidebarLink({
  name,
  to,
  badge,
  history,
  setOrgEntityName: setOrgName,
  setPersonEntityName: setPersonName,
}) {
  let onClick
  if (to === "/add-corporate-entity") {
    onClick = () => {
      setOrgName("")
      setPersonName("")
      history.push(to)
    }
  }
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      onClick={onClick}
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  )
}

SidebarLink.propTypes = {
  name: PropTypes.string,
  to: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.objectOf(PropTypes.any),
  setOrgEntityName: PropTypes.func,
  setPersonEntityName: PropTypes.func,
}

SidebarLink.defaultProps = {
  name: "",
  to: "",
  badge: 0,
  history: {},
  setOrgEntityName: () => {},
  setPersonEntityName: () => {},
}

const mapStateToProps = (state) => ({
  orgData: state.orgReducer.orgData,
})

const mapDispatchToProps = {
  setOrgEntityName,
  setPersonEntityName,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SidebarLink
  )
)
