import _ from "lodash"
// eslint-disable-next-line import/no-cycle
import {
  corporateRoutes,
  fundRoutes,
  adminRoutes,
  externalRoutes,
  idvRoutes,
  salesforceRoutes,
} from "../routes/index"

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

// export const isAML =
//   process.env.REACT_APP_AML_KYC_ACTIVE === true ||
//   process.env.REACT_APP_AML_KYC_ACTIVE === "true"

export const amlCaseNoteActions = [
  {
    value: "0",
    label: "No Action",
    backgroundColor: "#CCCCCC",
    color: "#FFFFFF",
  },
  {
    value: "1",
    label: "Set to Fail",
    backgroundColor: "#F7200D",
    color: "#FFFFFF",
  },
  {
    value: "2",
    label: "Set to Pass",
    backgroundColor: "#23B72B",
    color: "#FFFFFF",
  },
  {
    value: "3",
    label: "Set to Review",
    backgroundColor: "#FFBF00",
    color: "#FFFFFF",
  },
]

export const getPrivateRoutes = (appSettings) => {
  // const { appSettings } = getState().appConfig
  const routes = [corporateRoutes, fundRoutes]
  if (appSettings && appSettings.data) {
    const {
      data: { features },
    } = appSettings
    if (features) {
      const {
        coreIDV,
        corePms,
        coreAdmin,
        coreAdminUsers,
        coreSalesForce,
        coreAmlKyc,
      } = features
      if (corePms) {
        routes.push(externalRoutes)
      }

      if (coreSalesForce) {
        routes.push(salesforceRoutes)
      }

      if (coreAmlKyc && coreIDV) {
        routes.push(idvRoutes)
      }

      if (coreAdmin) {
        routes.push(
          adminRoutes({ isAdmin: coreAdmin, isUserAdmin: coreAdminUsers })
        )
      }
    }
  }
  return routes
}

export const getAppFeatures = (appSettings) => {
  // const { appSettings } = getState().appConfig
  // const features = {}
  if (appSettings && appSettings.data) {
    const {
      data: { features },
    } = appSettings
    if (features) {
      return features
    }
  }
  return {}
}

export const getCaseNoteAction = (rowData) => {
  if (!rowData || !_.isNumber(rowData.action_id)) {
    return null
  }
  // eslint-disable-next-line eqeqeq
  const action = _.find(amlCaseNoteActions, (i) => i.value == rowData.action_id)
  return action
}

export const getErrorMessage = (error) => {
  let message = ""
  if (error && error.response && error.response.data) {
    // console.log("getErrorMessage", error.response)
    const { errors } = error.response.data
    if (errors) {
      // console.log("getErrorMessage", errors)
      // eslint-disable-next-line camelcase
      // const keys = ["name", "description", "type", "version", "item_id"]
      // eslint-disable-next-line array-callback-return
      Object.keys(errors).map((kk) => {
        const { [kk]: keyErr } = errors
        if (_.isArray(keyErr) && keyErr.length > 0) {
          // eslint-disable-next-line array-callback-return
          keyErr.map((ele) => {
            message += `${ele}\n`
          })
        }
      })
    }
  }

  // console.log("message", message)
  return message
}

export const getTenantData = () => {
  let tenantData = null
  try {
    const tStr = localStorage.getItem("tenantData")
    if (tStr && tStr !== "") {
      tenantData = JSON.parse(tStr)
    } else {
      tenantData = null
    }
  } catch (error) {
    // console.log(error)
    tenantData = null
  }
  return tenantData
}

export const getTenantHeaders = () => {
  const headers = {}
  const tenantData = getTenantData()
  if (tenantData) {
    headers["X-Saffron-Tenant"] = tenantData.id
  }

  // console.log("getTenantHeaders", headers)

  return headers
}

export const fileSize = (x) => {
  let l = 0
  let n = parseInt(x, 10) || 0

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024
  }
  // include a decimal point and a tenths-place digit if presenting
  // less than ten of KB or greater units
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`
}

export const personRoleData = [
  {
    id: 21,
    label: "Controller",
  },
  {
    id: 24,
    label: "Admin",
  },
  {
    id: 22,
    label: "Director",
  },
]

export const organisationsRoleData = [
  {
    id: 18,
    label: "Controller",
  },
  {
    id: 19,
    label: "Director",
  },
  {
    id: 14,
    label: "Sub fund",
  },
  {
    id: 26,
    label: "Class",
  },
]

export const v2 = null
