import { combineReducers } from "redux"

import themeReducer from "./themeReducers"
import apiReducer from "./apiReducers"
import personReducer from "./personReducers"
import orgReducer from "./organisationReducers"
import userReducer from "./userReducer"
import appConfigReducer from "./appConfigReducer"
import idvReducer from "./idvReducer"
import appSettingsReducers from "./appSettingsReducers"
import documentReducer from "./documentReducer"

export default combineReducers({
  apiReducer,
  themeReducer,
  orgReducer,
  personReducer,
  userReducer,
  appConfigReducer,
  idvReducer,
  appSettingsReducers,
  documentReducer,
})
