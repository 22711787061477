import React from "react"
import { Users } from "react-feather"
import SettingsIcon from "@material-ui/icons/Settings"
import async from "../components/Async"
import { getTenantData } from "../common/utils"

// Features Configuration
// const isPMS = process.env.REACT_APP_PMS_ACTIVE === "true"
// const isSalesforce = process.env.REACT_APP_SALES_FORCES === "true"
// const isIDV = process.env.REACT_APP_IDV_ACTIVE === "true"
// const isAdmin = process.env.REACT_APP_ADMIN_ACTIVE === "true"
// const isUserAdmin = process.env.REACT_APP_USER_ADMIN_ACTIVE === "true"

// App

const getProjectAdminUrl = () => {
  let projectAdminUrl = ""
  try {
    const tenantData = getTenantData()

    let tenantDomain = ""
    if (tenantData !== null) {
      tenantDomain = `${tenantData.domain}.`

      if (tenantDomain === "localhost.") {
        tenantDomain = ""
      }
    }

    projectAdminUrl = `${process.env.REACT_APP_PROJECT_ADMIN_BASE}${tenantDomain}${process.env.REACT_APP_PROJECT_ADMIN}`
    console.log("admin url", projectAdminUrl)
  } catch (error) {
    console.log(error)
  }
  return projectAdminUrl
}

const CorporateEntities = async(() =>
  import("../components/CorporateEntity/CorporateEntities")
)
const FundEntities = async(() =>
  import("../components/CorporateEntity/FundEntities")
)
const AddCorporateEntity = async(() =>
  import("../components/CorporateEntity/AddCorporateEntity")
)
const AddPersonEntity = async(() =>
  import("../components/PersonEntity/AddPersonEntity")
)

const Base = async(() => import("../components/Base"))
const ProjectAdmin = async(() => {
  const projectAdminUrl = getProjectAdminUrl()
  window.location = projectAdminUrl
  return null
})

const Salesforce = async(() => import("../components/Salesforce/index"))

const IDVDashboard = async(() => import("../components/IDV/Dashboard"))

const AdminUsers = async(() => import("../components/Admin/Users"))

const Settings = async(() => import("../components/Settings/Settings"))

const CreateUserIndex = async(() =>
  import("../components/Admin/CreateUserIndex")
)

// Auth components
const Page404 = async(() => import("../pages/auth/Page404"))
const Page500 = async(() => import("../pages/auth/Page500"))

const NavIcon = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "",
  height = "100%",
  viewBox = "0 0 100 100",
}) => (
  <svg
    // height="100"
    // width="100"
    width={width}
    style={{ height: 30, width: 30, color: "#A5A5A5", opacity: 0.5 }}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="1" fill="none" />
  </svg>
)

export const baseRoutes = {
  id: "Dashboard",
  path: "/",
  header: "",
  icon: <NavIcon />,
  component: Base,
  children: null,
}

export const corporateRoutes = {
  id: "Corporate Entities",
  path: "/corporate-entities",
  component: CorporateEntities,
  // header: "Pages",
  icon: <NavIcon />,
  children: [
    {
      path: "/corporate-entities/list",
      name: "View Corporate Entities",
      component: CorporateEntities,
      visible: true,
    },
    {
      path: "/add-corporate-entity",
      name: "Add Corporate Entity",
      component: AddCorporateEntity,
      visible: true,
    },
    {
      path: "/add-fund-entity",
      name: "Add Fund Entity",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/edit-fund-entity/:id",
      name: "Edit Fund Entity",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/edit-corporate-entity/:id",
      name: "Edit Corporate Entity",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/:corporateId/add-person-entity",
      name: "Add Person Entity",
      component: AddPersonEntity,
      visible: false,
    },
    {
      path: "/:corporateId/add-person-entity/:contactType",
      name: "Add Admin Contact",
      component: AddPersonEntity,
      visible: false,
    },
    {
      path: "/:corporateId/add-corporate-entity",
      name: "Add Corporate Entity Controller",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/:corporateId/edit-corporate-entity/:id",
      name: "Edit Corporate Entity Controller",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/:corporateId/edit-fund-entity/:id",
      name: "Edit Fund Entity Controller",
      component: AddCorporateEntity,
      visible: false,
    },
    {
      path: "/:corporateId/edit-person-entity/:id",
      name: "Edit Person Entity Controller",
      component: AddPersonEntity,
      visible: false,
    },
    {
      path: "/add-person-entity/:id",
      name: "Add Person Entity",
      component: AddPersonEntity,
      visible: false,
    },
    {
      path: "/edit-person-entity/:id",
      name: "Edit Person Entity",
      component: AddPersonEntity,
      visible: false,
    },
  ],
}

export const fundRoutes = {
  id: "Funds Dashboard",
  path: "/corporate-entities/fund/list",
  component: FundEntities,
  visible: true,
  // header: "Pages",
  icon: <NavIcon />,
}

export const externalRoutes = {
  id: "Project Management (PMS)",
  path: "/project/admin",
  component: ProjectAdmin,
  // header: "Pages",
  icon: <NavIcon />,
  visible: true,
}

export const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
}

export const salesforceRoutes = {
  id: "Import",
  path: "/salesforce-import",
  component: Salesforce,
  visible: true,
  // header: "Pages",
  icon: (
    <>
      <NavIcon />
      <img
        src="https://www.salesforce.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg"
        height={50}
        alt="Salesforce"
        style={{ marginLeft: "12px", marginTop: "5px" }}
      />
    </>
  ),
}

export const idvRoutes = {
  id: "KYC Dashboard",
  path: "/kyc/dashboard",
  component: IDVDashboard,
  visible: true,
  // header: "Pages",
  icon: <NavIcon />,
}

export const userRoutes = [
  {
    path: "/admin/users",
    name: "Users",
    component: AdminUsers,
    visible: true,
  },
  {
    path: "/admin/users/create",
    name: "Create User",
    component: CreateUserIndex,
    visible: false,
  },
  {
    path: "/admin/user/:userId",
    name: "Edit User",
    component: CreateUserIndex,
    visible: false,
  },
  {
    path: "/admin/settings",
    name: "Settings",
    component: Settings,
    visible: true,
  },
]

export const adminRoutes = (isUserAdmin) => ({
  id: "Administration",
  path: "/admin",
  component: AdminUsers,
  icon: <SettingsIcon color="primary" size={30} />,
  children: isUserAdmin ? userRoutes : [],
})

// This route is not visisble in the sidebar
// const privateRoutes = {
//   id: "Private",
//   path: "/private",
//   // component: Blank,
//   children: null,
// }

// const cRoutes = {
//   id: "Corporate Entities",
//   path: "/corporate-entities",
//   component: CorporateEntities,
//   // header: "Pages",
//   icon: <NavIcon />,
//   children: [
//     {
//       path: "/edit-corporate-entity",
//       name: "Edit Corporate Entity",
//       component: AddCorporateEntity,
//     },
//   ],
// }

export const admin = ({ isAdmin, isUserAdmin }) =>
  isAdmin ? [adminRoutes(isUserAdmin)] : []

export const idv = (isIDV) => (isIDV ? [idvRoutes] : [])

export const salesforce = (isSalesforce) =>
  isSalesforce ? [salesforceRoutes] : []

export const base = [baseRoutes]

export const corporate = [corporateRoutes]

export const funds = [fundRoutes]

export const external = (isPMS) => (isPMS ? [externalRoutes] : [])

export const auth = [authRoutes]

const routes = [
  corporateRoutes,
  fundRoutes,
  externalRoutes,
  salesforceRoutes,
  idvRoutes,
  adminRoutes({ isAdmin: false, isUserAdmin: false }),
]

export default routes
