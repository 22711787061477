import React from "react"
import { Route, Switch, Redirect, Router } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  funds as fundRoutes,
  corporate as corporateRoutes,
  auth as authRoutes,
  external as externalRoutes,
  salesforce as salesforceRoutes,
  idv as idvRoutes,
  admin as adminRoutes,
} from "./index"

import DashboardLayout from "../layouts/Dashboard"
import AuthLayout from "../layouts/Auth"
import Page404 from "../pages/auth/Page404"
import history from "../common/history"
import PrivateRoute from "../components/PrivateRoute"
import IDVMain from "../components/IDV/index"
import { getAppFeatures } from "../common/utils"

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Add route for category parent
      ((
        <PrivateRoute
          key={String(index)}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ),
      // Route item with children
      // eslint-disable-next-line no-shadow
      children.map(({ path, component: Component }, index) => (
        <PrivateRoute
          key={String(index)}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )))
    ) : (
      // Route item without children
      <PrivateRoute
        key={String(index)}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  )

const Routes = (props) => {
  const {
    appConfig: { appSettings },
  } = props
  const features = getAppFeatures(appSettings)
  const {
    coreIDV,
    corePms,
    coreAdmin,
    coreAdminUsers,
    coreSalesForce,
    coreAmlKyc,
  } = features
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/corporate-entities/list" />}
        />
        {childRoutes(DashboardLayout, corporateRoutes)}
        {childRoutes(DashboardLayout, fundRoutes)}
        {childRoutes(
          DashboardLayout,
          adminRoutes({ isAdmin: coreAdmin, isUserAdmin: coreAdminUsers })
        )}
        {childRoutes(DashboardLayout, externalRoutes(corePms))}
        {childRoutes(DashboardLayout, salesforceRoutes(coreSalesForce))}
        {childRoutes(DashboardLayout, idvRoutes(coreIDV))}
        {childRoutes(AuthLayout, authRoutes)}
        {coreAmlKyc && idvRoutes(coreIDV).length && (
          <Route path="/kyc/:uniqueIdvId" component={IDVMain} />
        )}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  )
}

Routes.propTypes = {
  appConfig: PropTypes.objectOf(PropTypes.any),
}

Routes.defaultProps = {
  appConfig: {},
}

const mapStateToProps = (state) => ({
  appConfig: state.appConfigReducer,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
